html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
}

html{
  background-color: rgb(46, 46, 81);
  overflow-x: hidden !important;
  -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important; /* Safari */
     -khtml-user-select: none !important; /* Konqueror HTML */
       -moz-user-select: none !important; /* Old versions of Firefox */
        -ms-user-select: none !important; /* Internet Explorer/Edge */
            user-select: none !important;
}

::-webkit-scrollbar {
  width: 10px; 
  background-color: rgba(70,70,70,1);
}

.none::-webkit-scrollbar {
  width: 0px; 
  background-color: rgba(70,70,70,1);
}

::-webkit-scrollbar:hover {
  background-color: rgba(70,70,70,1);
}

::-webkit-scrollbar-thumb:vertical {
  background: rgba(110,110,110,1);
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0,0,0,1);
}

.logo {
  text-decoration: none;
  position: fixed;
  margin: 2em;
  top: 0;
  z-index: 100;
  width: 17em;
  height: auto;
  display: flex;
  flex-direction: column;
}

.logo img{
  width: 100%;
}

.logo a{
  color: #cafe9b;
  font-family: 'Montserrat', sans-serif;
}

.consul {
  text-decoration: none;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 13em;
  margin-right: 1em;
  margin-top: 0.25em;
  height: auto;
  right: 0;
  transform:translate(-20%,20%);
  animation: float3 4s ease-in-out infinite;

}

.consul img{
  width: 100%;
}

.logo-home{
  width: 30em;
  align-items: center;
}

.logo-container-center {
  display: flex;
  justify-content: center;
}

.introLogo{
  top: 0; 
  position: absolute;
  transform: scale(0.3) translateY(-75%)

}

.menu-container{
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}

.link-menu {
  font-family: 'Montserrat', sans-serif;
  color:rgb(112, 255, 213); 
  text-decoration: none;
  font-weight: 400;
  font-size: 1.2em;
  margin: 0.5em;
}

.link-menu img {
  width: 80%;
}

.subtitle{
  font-family: 'Montserrat', sans-serif;
  color:rgb(255, 255, 255); 
  text-decoration: none;
  font-weight: 700;
  font-size: 0.7em;
  margin: 0.5em;
}

.page-title-font {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.1em;
  color:#cafe9b; 
  font-weight: 400;
  font-size: 1.5em;
  margin: 0.5em;
  text-align: center;
  margin-top: 1.75em;
  background-color: transparent;
}

.page-subtitle-font {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.08em;
  color:#cafe9b; 
  font-weight: 700;
  font-size: 1.2em;
  margin: 0.5em;
  text-align: center;
  margin-top: 0em;
  margin-bottom: 2em;
}

.page-subtitle-font-white {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.08em;
  color:#ffffff; 
  font-weight: 700;
  font-size: 1.2em;
  margin: 0.5em;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 2em;
}

.page-para {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.08em;
  color:white; 
  font-weight: 400;
  font-size: 1.0em;
  margin: 0em;
  text-align: center;
  margin-top: 0em;
  margin-bottom: 3em;
}

.page-para-home{
  position: absolute;
  width: 40%;
  top: 150px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.08em;
  color:#cafe9b;
  text-align: center;
}

.page-para-bold {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.08em;
  color:#cafe9b;  
  font-weight: 700;
  font-size: 1.0em;
  margin: 0em;
  text-align: center;
  margin-top: 0em;
  margin-bottom: 3em;
}

.page-subtitle-font-left {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.08em;
  color:#cafe9b; 
  font-weight: 700;
  font-size: 1.2em;
  margin-top: 3em;
  margin-bottom: 1em;
  text-align: left;
}

.page-para-left {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.08em;
  color:white; 
  font-weight: 400;
  margin-bottom: 1em;
  font-size: 1.0em;
  text-align: left;
}

.down-arrow{
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.12em;
  color:#cafe9b; 
  font-weight: 400;
  font-size: 3.8em;
  text-align: center;
  margin-top: 1.5em;
  margin-bottom: 1.0em;
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  background: transparent;
  border: 0;
  bottom: 0;
  opacity: 1;
  cursor: pointer;
}

.down-arrow:hover{
  opacity: 0.7
}

.down-arrow:active{
  opacity: 1
}

.acteurs{
  margin-top: 2em;
  margin-bottom: 2em;
}

.acteurs1{
  margin-top: 2em;
}

.card-container {
  position: fixed;
  max-width: 70%;
  min-width: 1000px;
  display: none;
  height: 56%;
  left: 50%;
  top: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  min-height: 450px;
}

@keyframes gradientAnim1 {
  0% {
    transform:  translateX(115%) translateY(45%) scale(1.2);
 }
  50% {
    transform:  translateX(105%) translateY(50%) scale(1);
 }
  100% {
    transform:  translateX(115%) translateY(45%) scale(1.2);
 }
}

@keyframes gradientAnim2 {
  0% {
    transform: translateX(-75%) translateY(0%) scale(1.1);
 }
  50% {
    transform: translateX(-70%) translateY(10%) scale(1.2);
 }
  100% {
    transform: translateX(-75%) translateY(0%) scale(1.1);
 }
}

@keyframes gradientAnim3 {
  0% {
    transform:  translateX(55%) translateY(25%) scale(1.4);
 }
  50% {
    transform:  translateX(50%) translateY(30%) scale(1.5);
 }
  100% {
    transform:  translateX(55%) translateY(25%) scale(1.4);
 }
}

@keyframes gradientAnim4 {
  0% {
    transform: translateX(-85%) translateY(20%) scale(1.3);
 }
  50% {
    transform: translateX(-80%) translateY(30%) scale(1.4);
 }
  100% {
    transform: translateX(-85%) translateY(20%) scale(1.3);
 }
}

.gradient{
  position: fixed;
  z-index: -10;
  opacity: 1;
  transform: translateX(80%) translateY(50%) scale(1);
  animation: gradientAnim1 10s ease-in-out infinite;
}

.gradient2{
  position: fixed;
  z-index: -1;
  opacity: 1;
  transform: translateX(-75%) translateY(0%) scale(1.1);
  animation: gradientAnim2 10s ease-in-out infinite;
}

.gradient3{
  position: fixed;
  z-index: -10;
  opacity: 1;
  transform: translateX(80%) translateY(50%) scale(1);
  animation: gradientAnim3 10s ease-in-out infinite;
}

.gradient4{
  position: fixed;
  z-index: -1;
  opacity: 1;
  transform: translateX(-85%) translateY(0%) scale(1.1);
  animation: gradientAnim4 10s ease-in-out infinite;
}

#toggleBtn{
  color:#cafe9b;
  right: 1.5em;
  top: 1em;
  font-size: 1.2em;
  font-weight: 700;
  background-color:rgba(0, 0, 0, 0);
  border-color: #cafe9b;
  font-family: 'Montserrat', sans-serif;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 1em;
  cursor: pointer;
  z-index: 200;
  background: transparent;
  border-style: solid;
  box-shadow: 5px 5px 20px #00000070;
  text-shadow:rgba(0, 0, 0, 0.2);
  margin-top: 2em;;
}

#toggleBtn:hover{
  opacity: 0.7;
}

#toggleBtn:active{
  opacity: 1;
}

.photosphere{
  overflow-y: hidden !important;
}

.para-unveil{
  height: 34em;
}

.para-unveil img {
  transform: translateX(-50%);
  width: 835px;
  position: absolute;
  border-radius: 2em;
}

.para-unveil p {
  position: absolute;
  color: #cafe9b;
  text-shadow: 2px 2px 2px grey;
  font-size: 2em;
  font-weight: 700;
}

.icon-360{
  position: absolute;
  transform: translate(950%, -40%) scale(0.9);
  width: 5%;
  pointer-events: none;
  bottom: 0;
}

.icon-scroll{
  position: fixed;
  display: block;
  transform: translate(950%, -75%) scale(0.5);
  width: 5%;
  opacity: 0.9;
  pointer-events: none;
  bottom: 0;
}

.showCard{
  display: flex !important;
}

.home-vid-container{
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  display: flex;
  z-index: 100;
  height: 100%;
  width: 100%;
  background-color:rgba(0, 0, 0, 1);
  justify-content: center;
}

.inter-vid-container{
  left: 0;
  top: 0;
  overflow-x: hidden;
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 60%;
  margin-left: 20%;
  justify-content: center;
}

#mainVid {
  top: 0;
  width: auto;
  height: 100%;
}

.skip{
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 3em;
  margin-bottom: 4em;
  margin-right: 8%;
  font-family: 'Montserrat', sans-serif;
  color:rgb(255, 255, 255); 
  font-size: 1.0em;
  padding-bottom: 2.5em;
  border: 0;
  font-weight: 700;
  background: transparent;
  border-color: rgb(112, 255, 213);
  cursor: pointer;
  border-style: solid;
  text-decoration: underline;
}

.skip:hover{
  opacity: 0.5;
}

.skip:active{
  opacity: 1;
}

.close-btn {
  color:rgb(112, 255, 213); 
  position: absolute;
  right: 1.5em;
  top: 1em;
  font-size: 2.5em;
  background-color:rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  font-family: 'Montserrat', sans-serif;
  transform: scaleX(1.2);
}

.image-gallery-icon{
  color:rgb(12, 255, 213) !important;
  transition: all 0.2s !important;
  transform: scale(0.75) translateY(-66%) !important;
}

.image-gallery-icon:hover{
  color:rgb(121, 255, 233) !important;
  transform: scale(0.75) translateY(-66%) !important;
  transition: all 0.2s !important;
}

.close-btn:hover {
  opacity: 0.5;
  cursor: pointer;
}

.close-btn:active {
  opacity: 1;
}

.card {
  display: flex;
  background-color:rgba(46, 46, 81, 0.8);
  padding: 2em;
  padding-top: 6em;
  padding-bottom: 6em;
  border-radius: 1em;
  box-shadow: 5px 5px 20px #00000070;
}

.card img {
  height: auto;
  box-shadow: 5px 5px 20px #00000040;
}

.image-gallery{
  width: 120%;
  transform: translateX(-20%);
  overflow: hidden;
  max-height: 100%;
  display: block;
  align-items: center;
}

.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg  {
width: 50px !important;
height: 50px !important;
margin-bottom: -4em;
}

.card-content {
  margin: 3em;
  margin-left: 1em;
  margin-top: 0;
  margin-bottom: 5em;
  max-width: 50%;
}



.card-content h2 {
  font-family: 'Montserrat', sans-serif;
  color:rgb(112, 255, 213); 
  font-weight: 700;
  font-size: 2em;
  padding-bottom: 0.5em
}

.card-content p {
  font-family: 'Montserrat', sans-serif;
  color:rgb(255, 255, 255); 
  font-weight: 400;
  font-size: 1em;
  max-width: 100%;
  margin: 0!important;
  overflow-y: scroll;
  height: 48%;
  padding-right: 1.5em;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 1em;
}

.card-content .card-text-full {
  height: 95%;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.project {
  height: 400px;
  background-color: rgba(0, 0, 0, 0);
  margin: 1em;
  padding-top: 2em;
  padding-bottom: 10em;
  color:rgb(255, 255, 255);
  font-size: 2em;
  z-index: -100;
  opacity: 1;
  pointer-events: none;
  transform: translateY(0px);
  transition: all 1s ease-out;
}

.project.fade-in{
  opacity: 1;
  transform: translateY(0px);
}

.container {
  position: fixed;
  width: 100%;
  height: auto;
  display: grid;
  z-index: -11;
  margin-top: 2em;
  background-color:rgba(0, 0, 0, 0);
  pointer-events: none;
}

.element{
  opacity: 0;
}

#red{
  background-color: blue;
  display: list-item;
}

#yellow{
  display: list-item;
  background-color: white;
}

#green{
  display: list-item;
  background-color: red;
}

.lottieBG{
  position: fixed;
  top: 28%;
  width: 100%;
  z-index: -1;
  display: block;
}

.videoPlayer{
  height: 100%;
  box-shadow: 5px 5px 20px #00000070;
}

.videoPlayerInterview{
  top: 0;
}

.video-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
  width: 60%;
  margin-top: 0em;
  margin-left: 20%;
  margin-right: 20%;
  background-color:rgba(0, 0, 0, 0);
  justify-content: center;
}

.video-container-el{
  margin: 1em;
}

.video-container video{
  width: 100%;
  height: 100%;
}

.video-container iframe{
  width: 100%;
  height: 100%;
  
}

.pod-container{
  display: flex;
  align-items: center;    
  justify-content: center;
  flex-direction: column;
}

.pod-container .pod-img{
  width: 100%;
  height: auto;
  margin-left: 25%;
  margin-right: 25%;
  box-shadow: 5px 5px 20px #00000070;
  border-radius: 1em;
}

.pod-bg{
  position: absolute;
  width: 50%;
  justify-content: center !important;
  display: flex;
  box-shadow: 5px 5px 20px #00000070;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 1em;
  height: 40%;
  margin-left: 25%;
  margin-right: 25%;
}

.video-btns{
  position: absolute;
  width: 100%;
  justify-content: center;
  display: flex;
  height: 100%;
  border: none;
  z-index: 2;
}

.video-btns button {
  background-color: transparent;
  border: transparent;
  transform: scale(25%);
  cursor: pointer;
}

.info-container{
  margin-top: 3em;
  margin-bottom: 10em;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  color:rgb(255, 255, 255); 
  font-weight: 400;
  font-size: 1em;
}

.info-title{
  font-weight: 700;
  font-size: 1.5em;
  color: #cafe9b;
}

.info-subtitle{
color: #cafe9b;
  font-weight: 700;
  font-size: 1.1em;
}

@keyframes float1 {
  0% {
    transform: translatey(10px);
 }
  50% {
    transform: translatey(-10px);
 }
  100% {
    transform: translatey(10px);
 }
}

@keyframes float2 {
  0% {
    transform: translatey(-10px);
  }
  50% {
    transform: translatey(10px);
 }
  100% {
    transform: translatey(-10px);
 }
}

@keyframes float3 {
  0% {
    transform: translatey(-25px) scale(1.5);
  }
  50% {
    transform: translatey(-22px) scale(1.6);
 }
  100% {
    transform: translatey(-25px) scale(1.5);
 }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
 }
  50% {
    transform: scale(1);
 }
  100% {
    transform: scale(0.8);
 }
}

#map-link{
  animation: float1 6s ease-in-out infinite;
}

#suivre-link{
  animation: float2 6s ease-in-out infinite;
}

#photo-link{
  animation: float2 5s ease-in-out infinite;
}

#pod-link{
  animation: float2 6s ease-in-out infinite;
}

#inter-link{
  animation: float1 5s ease-in-out infinite;
}

#para-link{
  animation: float2 5s ease-in-out infinite;
}

#canvas{
  display: flex;
  box-shadow: 5px 5px 20px #00000070;
  position:absolute;
  top: 0;
  z-index: -1
}

canvas{
  width: 100% !important;
}

footer{
  padding-top: 6em;
  padding-bottom: 2em;
  display: none;
  justify-content: center;
  flex-direction: column;
  bottom: 0;
  position: relative;
  z-index: 1;
  width: 100%;
  align-items: center;
}

footer p {
  font-family: 'Montserrat', sans-serif;
  color:rgb(255, 255, 255); 
  font-weight: 400;
  font-size: 1em;
  z-index: -2;
  font-weight: 500;
  text-shadow: 3px 3px 3px black;
}

footer a {
  font-family: 'Montserrat', sans-serif;
  color:#cafe9b; 
  text-decoration: none;
}


.date{
  font-family: 'Montserrat', sans-serif;
  color:#cafe9b;
  font-weight: 700;
  font-size: 1.5em;
}

.map-title{
  font-family: 'Montserrat', sans-serif;
  color:rgb(255, 255, 255);
  font-weight: 400;
}

.map-el{
  position: absolute;
  width: 100%;
  top: 0;
  opacity: 0;
}

.map-container{
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-left: 10%;
}

.map-text-container{
  width: 35%;
  min-width: 350px;
  position: relative;
  margin-right: 15%;
}

.map-img-container{
  width: 100%;
}

.map-img{
  position: absolute;
  width: 50%;
}

.aquisition{
  transform: translateY(-28%) translateX(0%) scale(0.45);
  width: 50%;
}

.para-container{
  height: 100%;
  display: flex;
  z-index: -1;
  position: absolute;
  width: 88%;
  top: 0;
  left: 0;
  pointer-events: none;
  transform: scale(0.5) translateY(-50%) translateX(12%);
}

.para-pic{
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 0;
  margin-bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

.mute-btn{
  position: fixed;
  bottom: 0;
  width: 6em;
  background-color:rgba(0, 0, 0, 0);
  margin: 1em;
  border: none;
  background: none;
  z-index: 10;
}


.mute-btn:hover{
  opacity: 0.6;
  cursor: pointer;
}

.mute-btn:active{
  opacity: 1;
}

.mute-btn img{
  width: 100%;
}

.launcher-btn-container{
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10000;
  border: 0;
  height: 100%;
  background: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.launcher-btn-container button{
  border: 0;
  background:transparent;
  cursor: pointer;
  transition: all 0.25s;
  opacity: 0.8;
  bottom: 0;
}

.launcher-btn-container button:hover{
  opacity: 1;
  transition: all 0.25s;
  transform: scale(1.1) translateY(-25px);
}

.launcher-btn-container button:active{
  opacity: 0.8;
}

.launcher-btn-container button img{
  width: 400px;
  transform: translateY(80px);
}

.logo-ville-container{
  position: fixed;
  bottom: 0;
  width: 6em;
  z-index: 10000;
  background-color:rgba(0, 0, 0, 0);
  border: none;
  background: none;
}

.logo-ville{
  left: 0;
  top: 0;
  background-color:rgba(0, 0, 0, 0);
  margin: 1em;
  background: none;
  width: 75px !important;
  margin: 3em;
  transform: scale(1)
}

.launcher{
  position: fixed;
  width: 25%;
  height: 100%;
  background-color:rgba(0, 0, 0, 0);
  border: none;
  transform: translateY(-0%);
  background: none;
  z-index: 100;
  background-color: rgb(46, 46, 81);
  padding-left: 37.5%;
  padding-right: 37.5%;
  padding-top: 300px;
  padding-bottom: 37.5%;
  transition: all 0.5s;
  top: 0;
  display: flex;
  justify-content: center;
  left: 0;
}

.launcher button{
  transition: all 0.25s;
  background-color:rgba(0, 0, 0, 0);
  border: 0;
  width: auto;
  opacity: 0.7;
  height: 0;
  margin-top: 12em;
  bottom: 0;
}

.topBtn{
  bottom: 0;
  width: 10%;
  margin: 45%;
  margin-bottom: 4%;
  font-size: 2em;
  background-color:rgba(0, 0, 0,0);
  border: 0;
  letter-spacing: 0.12em;
  color:#cafe9b; 
  font-weight: 400;
  cursor: pointer;
}

.launcher button:hover{
  transform: translateY(-5%) scale(1.05);
  cursor: pointer;
  transition: all 0.25s;
  opacity: 1;
}

.launcher button:active{
  opacity: 0.0;
}

.launcher img{
  width: 100%;
}

.react-audio-element{
  background-color:#74925a;
  border-radius: 2em;
  padding: 2em;
  margin-top: 2em;
  box-shadow: 5px 5px 20px #00000030;
  z-index: 50;
  margin-bottom: 8em;
}

.paysage{
  display: none; 
  background-color: rgb(46, 46, 81);
  color: #cafe9b;
  background-color: rgb(46, 46, 81);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000000;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 600px) {

  .paysage{
    display: flex; 
    flex-direction: column;
    align-items: center;
    color: #cafe9b;
    background-color: rgb(46, 46, 81);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000000;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
  } 

  .paysage img{
    width: 150px;
    margin-top: 3em;
  } 
}

@media only screen and (max-width: 920px) {

  .launcher-btn-container button img{
    width: 200px;
    transform: translateY(50px);
  }

  .consul{
    width: 6em;
    margin-top: 2em;
    z-index: 1000;
  }

  .page-title-font{
    font-size: 1em;
  }

  .logo{
    margin-top: 1.5em;
    margin-left: 1.0em;
  }

  .logo img {
    width: 50%;
  }

  .page-subtitle-font{
    font-size: 0.8em;

  }

  .link-menu{
    margin: 0;
    margin-left:1.0em;
  }

  #home-link{
    margin-top: 3.5em !important;
    margin-left: 1.25em !important;
    z-index: 100 !important;
  }

  .page-para{
    width: 60%;
    pointer-events: none;
    font-size: 0.8em;
    margin-bottom: 0;
  }

  .patenaire-container{
    padding-left: 3.5em !important;
    grid-template: auto / 100% !important;

  }

  .page-subtitle-font-left{
    padding-left: 2em;
  }

  .page-para-left{
    padding-left: 2.5em;
  }
  
  .para-unveil{
    height: 15.5em !important;
  }

  .para-unveil img{
    width: 400px;
  }

  .pod-arrow-container{
    transform: translateY(500%) translateX(-50%) scale(0.75) !important
  }

  .pod-arrow-container .right img{
    width: 30px !important;
    height: 25px !important;
  }
  
  .pod-arrow-container .left img{
    width: 30px !important;
    height: 25px !important;
  }

  .mute-btn img{
    width: 75%;
  }

  .container{
    margin-top: 0em;
  }

  .icon-scroll{
    display: none
  }

  .map-container{
    width: 100%;
    margin-left: 5%;
  }

  .map-title{
    font-size: 0.7em;
    line-height: 0em;
  }
  
  .date{
    font-size: 1em;
    line-height: 0.0em;
  }

  .card-container {
      min-width: 650px;
  }

  .card-content{
    transform: translateY(-20%);
  }

  .card-content p{
    font-size: 0.7em;
  }

  .rhap_container{
    margin-top: 1.0em !important;
  }

  .card-content h2{
    font-size: 1em;
  }

  footer{
    padding-bottom: 1em;
  }

  .info{
    width: 50%;
  }

  .map-text{
    width: 100%;
  }

  .para-container{
    width: 76%;
    pointer-events: none;
    transform: scale(0.45) translateY(-60%) translateX(34%);
  }

  .logo-ville{
    margin: 1.5em;
    margin-left: 2.5em;
  }

  .map-text-container{
    margin-right: 10%;
  }

  .map-el br{
    display: none;
  }

  .close-btn {
    color:rgb(112, 255, 213); 
    position: absolute;
    right: 1.5em;
    top: 2em;
    font-size: 1.5em;
    background-color:rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    font-family: 'Montserrat', sans-serif;
    transform: scaleX(1.2) translateY(5px) translateX(5px);
  }

  .down-arrow{
    margin-bottom: 0.5em;
    padding-right: 1.0em;
  }

  .page-para-home{
    width: 60%;
    top: 20%;
    font-size: 0.8em;
  }

  .card-content .card-text-full {
    height: 95%;
  }
  
  .card-container{
    top: 62%;
  }

  .logo{
    margin: 1em;
  }

  .skip{
    margin-bottom: 2em;
  }

  .introLogo{
    transform: scale(0.3) translateY(-100%);
  }

  .logo-home{
    margin-top: 0.5em !important;
  }

  .btn-sphere{
    height: 16px !important;
    width: 20px !important;
  }

  .video-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    width: 100%;
    margin: 0;
    margin-top: 0em;
    background-color:rgba(0, 0, 0, 0);
    justify-content: center;
  }

  .page-subtitle-font-white {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.08em;
    color:#ffffff; 
    font-weight: 700;
    font-size: 1.2em;
    margin: 0.5em;
    text-align: center;
    margin-top: 0em;
    margin-bottom: 0.5em;
  }

  .pod-container{
    display: flex;
    margin-top: 2em;
    align-items: center;    
    justify-content: center;
    flex-direction: column;
  }


}

