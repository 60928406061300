$bg-color: rgb(46, 46, 81);
$link-color: #cafe9b;
$link-home: #00ff95;

.Question-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.question-container{
  padding: 0.5em;
  width: 50%;
  display: flex;
  flex-direction: column;
  .question-title{
    background-color: rgb(74, 75, 144);
    color: cyan;
    font-weight: 700;
    padding: 0.25em;
    padding-left: 0.5em;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
  
  }
  .question-answer{
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    color: azure;
    font-size: 1em;
    font-weight: 300;
    background-color: transparent;
  }
  .question-img{
    width: 100%;
  }
  video{
    width: 100%;
  }
}

.hide{
  display: none;
}

.header-page{
  //background-image: linear-gradient(rgba(46, 46, 81, 1),rgba(46, 46, 81, 1));
  background-image: transparent;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  height: auto;
  z-index: 99;
  align-items: center;
  pointer-events: none;
  top: 0;
  .text-wrappper{
    height: auto;
    display: flex;
    flex-direction:column;
    align-items: center;
  }
  button {
    margin-left: 1em;
    background: transparent;
    border: none;
    font-size: 3em;
    font-weight: 700;
    color: $link-color;
    cursor: pointer;
  }
  button:hover{
    opacity: 0.6;
  }
}

.header-page-scroll{
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  height: auto;
  z-index: 99;
  align-items: center;
  pointer-events: none;
  top: 0;
  .text-wrappper{
    height: auto;
    display: flex;
    flex-direction:column;
    align-items: center;
  }
  button {
    margin-left: 1em;
    background: transparent;
    border: none;
    font-size: 3em;
    font-weight: 700;
    color: $link-color;
    cursor: pointer;
  }
  button:hover{
    opacity: 0.6;
  }
}

.rotate{
  transform: rotate(90deg);
}

.rhap_container{
  margin-top: 1.0em;
  border-radius: 1em;
  background-color: rgb(71, 71, 124);
}

.rhap_time{
  color: white;
}

.player-container-card{
  margin-right: 1em;
}

.podPlayer{
  width: 375px;
  margin-top: 2em;
  z-index: 100;
}

.pod-btn-container{
  margin-bottom: 1.5em;
  z-index: 2;
  .pod-btn{
    margin: 0.25em;
    padding: 0.5em;
    border-radius: 30em;
    border: 0;
  }
  .pod-btn:hover{
    opacity: 0.6;
    cursor: pointer;
  }
  .pod-btn-on{
    background-color: rgb(24, 255, 186);
  }
}

.pod-arrow-container{
  position: absolute;
  z-index: 1000;
  transform: translateY(250px) translateX(-50%);
}

.pod-arrow-container button{
  background: transparent;
  color: rgb(24, 255, 186);
  font-family: 'Montserrat', sans-serif;
  font-size: 6em;
  font-weight: 400;
  border: 0;
}

.pod-arrow-container button:hover{
  cursor: pointer;
  opacity: 0.6;
}

.pod-arrow-container button:active{
  opacity: 1;
}

.pod-arrow-container .right{
  transform: translateX(325px) scaleY(2);
}

.pod-arrow-container .left{
  transform: translateX(-325px) scaleY(2);
}

.pod-arrow-container .right img{
  height: 40px;
  width: 50px;
}

.pod-arrow-container .left img{
  height: 40px;
  width: 50px;
}

.sphere-arrow-container{
  position: absolute;
  z-index: 1000;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}

.sphere-arrow-container button{
  background: transparent;
  color: #00ff95;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5em;
  font-weight: 400;
  border: 0;
  pointer-events: visible;
  transform: scaleY(3) scaleX(1.5);
  margin: 0.5em;
}

.sphere-arrow-container button:hover{
  opacity: 0.6;
  pointer-events: visible;
  cursor: pointer;
}

.sphere-arrow-container button:active{
  opacity: 1;
}

#footer-mention{
  color: $link-color;
  text-decoration: underline;
}

#home-link {
  top: 0;
  font-weight: 400;
  margin: 3em;
  margin-top: 6em;
  color: #cafe9b;
  text-decoration: none;
  position: fixed;
  z-index: 100;
}

.patenaire-container{
  margin-top: 1em;
  display: grid;
  grid-template: auto / 33% 33% 33%;
  grid-gap: 10px;
  padding: 10px;
  padding-left: 0px;
}

.patenaire{
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
  img{
    width: auto;
    max-height: 70px;
    margin-bottom: 0;
    display: flow-root;
  }
  p{
    font-size: 0.5em;
    text-align: initial;
    font-family: 'Montserrat', sans-serif;
    padding: 1.5em;
    padding-right: 5em;
    padding-left: 0;
    color: white;
  }

}

.btn-sphere{
  height: 25px;
  width: 30px;
}
